<template>
  <b-card-code title="حالة الطلب">
    <b-row>
      <b-col>
        <div class="" style="font-weight: bold; color: black">
          رقم الطلب:
          <b style="font-weight: 800">
            {{ this.$route.params.id }}
          </b>
        </div>
      </b-col>
    </b-row>

    <!-- select 2 demo -->
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-orderStatus
      variant="outline-primary"
      class="btn-primary"
    >
      تعديل حالة الطلب
    </b-button>

    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-orderCancel
      variant="outline-primary"
      class="m-50 btn-danger"
    >
      الغاء الطلب
    </b-button>

    <!-- select 2 demo -->
    <b-modal
      @hidden="clearForm"
      hide-footer
      ref="myModal"
      id="modal-orderStatus"
      title="تعديل  حالة الطلب"
      ok-title="submit"
      cancel-variant="danger"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="cancelForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="initValues"
        >
          <b-form-group label="اختار حالة الطلب" label-for="vue-select">
            <!-- <v-select -->
            <b-form-select
              id="vue-select"
              v-model="status_id"
              :options="options"
            />
            <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!loading"
            >
              إرسال
            </b-button>
            <!-- spinner -->
            <b-button v-if="loading" variant="primary" disabled class="mr-1">
              <b-spinner small />
              Loading...
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- select 2 demo -->
    <b-modal
      @hidden="clearForm"
      hide-footer
      ref="myModal"
      id="modal-orderCancel"
      title="الغاء الطلب"
      ok-title="submit"
      cancel-variant="danger"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="brnadForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onCancelSubmit)"
          @reset.prevent="initValues"
        >
          <!--  Name -->
          <validation-provider
            #default="validationContext"
            name="reason"
            rules="required"
          >
            <b-form-group label="السبب" label-for="reason">
              <b-form-input
                id="reason"
                v-model="reason"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="أكتب سبب الالغاء"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.reason">{{
                allerrors.reason[0]
              }}</span>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!loading"
            >
              إرسال
            </b-button>
            <!-- spinner -->
            <b-button v-if="loading" variant="primary" disabled class="mr-1">
              <b-spinner small />
              Loading...
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
import {
  BLink,
  BCard,
  BAvatar,
  BBadge,
  BOverlay,
  BRow,
  BCol,
  BSidebar,
  BForm,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BCardText,
  BMedia,
  BSpinner,
  BFormSelect,
  BModal,
  VBModal,
  BAlert,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { onMounted, ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { watch } from "@vue/composition-api";
import DataService from "../data/services";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BCardCode from "@core/components/b-card-code";

import { codeBasic } from "./code";

export default {
  components: {
    BModal,
    BAlert,
    BCardCode,
    BLink,
    BCard,
    BBadge,
    BOverlay,
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    BSpinner,
    BFormSelect,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      codeBasic,

      loading: false,
      isLoading: false,
      allerrors: [],

      status_id: null,
      reason: null,

      orderId: this.$route.params.id,
      options: [
        { value: 0, text: "في انتظار عروض الاسعار" },
        { value: 1, text: "مؤكدة" },
        { value: 2, text: "تم الوصول لموقع الطلب" },
        { value: 3, text: "مكتملة" },
        { value: 4, text: "ملغية" },
        { value: 5, text: "في الطريق لموقع التسليم" },
        { value: 6, text: "تم الوصول لموقع التسليم" },
      ],
    };
  },

  mounted() {},
  methods: {
    prepareData() {
      return {
        orderId: this.orderId,
        status_id: this.status_id,
      };
    },
    onSubmit() {
      this.sendData();
    },
    sendData() {
      this.loading = true;
      this.allerrors = [];

      DataService.updateStatus(this.prepareData())
        .then((response) => {
          this.loading = false;

          let responseCode = response.status;
          if (responseCode == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "EditIcon",
                variant: "success",
                text: "تم اﻹرسال بنجاح",
              },
            });

            this.$emit("refreshData", this.orderId);

            this.$refs.myModal.hide();
          } else if (responseCode === 101) {
            console.log("error");
            this.allerrors = response.data.errors;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          } else if (responseCode == 102) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;

          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error;
          }
          let message = Error;
          if (error.response !== undefined) {
            message = error.response.data.message;
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              text: message,
              variant: "danger",
            },
          });
        });
    },
    prepareCancelData() {
      return {
        orderId: this.orderId,
        cancelReason: this.reason,
      };
    },
    onCancelSubmit() {
      this.loading = true;
      this.allerrors = [];

      DataService.cancelOrder(this.prepareCancelData())
        .then((response) => {
          this.loading = false;

          let responseCode = response.status;
          if (responseCode == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "EditIcon",
                variant: "success",
                text: "تم اﻹرسال بنجاح",
              },
            });

            this.$emit("refreshData", this.orderId);

            this.$refs.myModal.hide();
          } else if (responseCode === 101) {
            console.log("error");
            this.allerrors = response.data.errors;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          } else if (responseCode == 102) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: response.data.responseDescription,
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;

          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error;
          }
          let message = Error;
          if (error.response !== undefined) {
            message = error.response.data.message;
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              text: message,
              variant: "danger",
            },
          });
        });
    },
    clearForm() {
      // Reset form data when the modal is closed
      this.formData = {
        orderId: "",
        status_id: "",
      };
      console.log("Form cleared");
    },
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation();
    return {
      refFormObserver,
      getValidationState,
      resetForm,
      //  ? Demo - Update Image on click of update button
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
