<template>
  <b-card title="تفاصيل الطلب">
    <table class="mt-2 mt-xl-0 w-100">
      <tr>
        <th class="pb-50">
          <feather-icon icon="CalendarIcon" class="mr-75" />
          <span class="font-weight-bold">التاريخ</span>
        </th>
        <td class="pb-50">
          {{ orderData.preferredDate }}
        </td>

        <th class="pb-50">
          <feather-icon icon="ClockIcon" class="mr-75" />
          <span class="font-weight-bold">الوقت</span>
        </th>
        <td class="pb-50">
          {{ orderData.preferredTime }}
        </td>

        <th class="pb-50">
          <feather-icon icon="BarChart2Icon" class="mr-75" />
          <span class="font-weight-bold">الحالة</span>
        </th>

        <td class="pb-50 text-capitalize">
          <b-badge :class="getStatusClass(orderData.status)">
            {{ orderData.statusText }}
          </b-badge>

          <!-- <template #cell(status)="">
        <b-badge  :variant="status[1][data.value]" style="cursor: pointer;">
          {{ status[0][data.value] }}
        </b-badge>
      </template> -->
        </td>

        <th class="pb-50">
          <feather-icon icon="HashIcon" class="mr-75" />
          <span class="font-weight-bold">عدد اﻷصناف</span>
        </th>
        <td class="pb-50 text-capitalize">
          {{ orderDataItemsNumber }}
        </td>
      </tr>

      <tr>
        <th class="pb-50">
          <feather-icon icon="DollarSignIcon" class="mr-75" />
          <span class="font-weight-bold">رسوم الخدمة</span>
        </th>
        <td class="pb-50">
          {{ orderData.serviceFee }}
        </td>

        <th class="pb-50">
          <feather-icon icon="AwardIcon" class="mr-75" />
          <span class="font-weight-bold">عمولة</span>
        </th>
        <td class="pb-50">
          {{ orderData.commission }}
        </td>

        <th class="pb-50">
          <feather-icon icon="PercentIcon" class="mr-75" />
          <span class="font-weight-bold">ضريبة القيمة المضافة</span>
        </th>

        <td class="pb-50 text-capitalize">
          {{ orderData.vat }}
        </td>

        <th class="pb-50">
          <feather-icon icon="DollarSignIcon" class="mr-75" />
          <span class="font-weight-bold">اﻹجمالي</span>
        </th>
        <td class="pb-50 text-capitalize">
          {{ orderData.total }}
        </td>
      </tr>

      <tr>
        <!-- <th class="pb-50">
              <feather-icon
                icon="HashIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الطابق</span>
            </th>
            <td class="pb-50">
               {{orderData.floor}} 
            </td> -->

        <th class="pb-50">
          <feather-icon icon="TypeIcon" class="mr-75" />
          <span class="font-weight-bold">الملاحظة</span>
        </th>
        <td class="pb-50">
          {{ orderData.note }}
        </td>
      </tr>
    </table>
  </b-card>
</template>

<script>
import { BCard, BBadge } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BBadge,
  },
  props: {
    orderData: {
      type: [Object, Array],
      required: true,
    },
    orderStatus: {
      type: Number,
      required: true,
    },
    orderDataItemsNumber: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      // status: [{
      //   0: 'في انتظار عروض الاسعار', 1: 'مؤكدة', 2: 'تم الوصول لموقع الطلب', 3: 'مكتملة', 4: 'ملغية', 5: 'تم الوصول لموقع الطلب', 6: 'في الطريق لموقع التسليم',
      //   },
      //   {
      //     0: 'badge-primary', 1: 'badge-info', 2: 'badge-danger', 3: 'badge-success', 4: 'badge-danger', 5: 'badge-info', 6: 'badge-info',
      //   }
      // ],
    };
  },
  methods: {
    getStatusClass(status) {
      console.log("status: in method :  " + status);
      // if(status == 2)
      //   return 'badge-danger';
      // return {
      //   'badge-danger': status == 0,
      // 'badge-info': status == 1,
      // 'badge-danger': status == 2,
      // 'badge-success': status === '3',
      // 'badge-danger': status === '4',
      // 'badge-info': status === '5',
      // 'badge-info': status === '6',
      // };

      const statusClasses = {
        0: "badge-warning",
        1: "badge-primary",
        2: "badge-info",
        3: "badge-success",
        4: "badge-danger",
        5: "badge-secondary",
        6: "badge-secondary",
      };
      return statusClasses[status] || "badge-secondary";
    },
  },
  mounted() {
    // this.getStatusClass(this.orderStatus)
  },
};
</script>
